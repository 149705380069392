import {ISignUpSetActivationSentAction, ISignUpSetTempPasswordSentAction, SignUpActionType} from "./types";

import {postAction} from '../utils';
import {TTResult} from "..";
import urls from '../../urls';

export const SignUpSetActivationSentAction=(activationSent:boolean): ISignUpSetActivationSentAction=>{
  return {type: SignUpActionType.SIGN_UP_SET_ACTIVATION_SENT, activationSent};
}

export const SignUpSetTempPasswordSentAction=(tempPasswordSent:boolean): ISignUpSetTempPasswordSentAction=>{
  return {type: SignUpActionType.SIGN_UP_SET_TEMP_PASSWORD_SENT, tempPasswordSent};
}

export const SignUpAction=(username: string, firstName: string, lastName: string, email: string):
    TTResult<Promise<any>> => async(dispatch, getState) => {
  const payload={username, firstName, lastName, email};
  const continuation=(data: any, dispatch: any)=>{
    dispatch(SignUpSetActivationSentAction(true));
    return { success: true };
  }
  return await postAction(urls.SIGN_UP_URL, payload, continuation)(dispatch,getState);
}

export const SignUpActivateAction=(uidb64: string, token: string):
    TTResult<Promise<any>> => async(dispatch, getState) => {
  const payload={uidb64, token};
  const continuation=(data: any, dispatch: any)=>{
    dispatch(SignUpSetTempPasswordSentAction(true));
    return { success: true };
  }
  return await postAction(urls.ACTIVATE_URL, payload, continuation)(dispatch,getState);
}
