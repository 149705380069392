import {RootState} from "../store";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

export type TTResult<Result> = ThunkAction<Result, any, any, Action>;
export type TTDispatch=ThunkDispatch<any, any, Action>;

export type GetStateFunc=RootState;

export type IContinuationResult = { success: boolean, result?: any, error?: any };
export type IContinuation<TResponse> = (data: TResponse, dispatch: any)=>IContinuationResult;

export type IPayloadBuilderFunc<TResult> = (getState: GetStateFunc)=>TResult;
export type IPayload<TPayload> = IPayloadBuilderFunc<TPayload>|TPayload;
export type IPostSuccessfulFunc = (content: any) => boolean;
export type PostMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS';

export enum Types {
  STRING = "string",
  NUMBER = "number",
  OBJECT = "object",
  FUNCTION = "function",
  BOOLEAN = "boolean",
}

export interface TypeSelectorHook<TState,TSelected> {
  (
      selector: (state: TState) => TSelected,
      equalityFn?: (left: TSelected, right: TSelected) => boolean
  ): TSelected;
}

export class SessionTokenExpiredError extends Error {
  constructor(message: string) {
    super(message);
    this.name="SessionTokenExpiredError";
  }
}

export class AuthTokenExpiredError extends Error {
  constructor(message:string) {
    super(message);
    this.name="AuthTokenExpiredError";
  }
}
