import React          from 'react';
import {Button}       from 'antd';
import {FaIcon,FaSet}            from "..";
import {ButtonShape, ButtonType} from "antd/lib/button";

export type HtmlButtonType = 'button' | 'reset' | 'submit';

export interface IFaButtonCommonProps {
  className?:    string,
  disabled?:     boolean,
  button?:       ButtonType,
  size?:         'small' | 'large',
  icon:          string,
  set?:          FaSet,
  text?:          string,
  tooltip?:      string,
  id?:           string,
  modalVisible?: boolean,
  htmlType?:     HtmlButtonType,
  style?:        React.CSSProperties,
  iconSide?:     "left" | "right",
  danger?:       boolean,
  block?:        boolean,
  shape?:        ButtonShape,
  loading?:      boolean,
}

export interface IFaButtonProps extends IFaButtonCommonProps{
  onClick?:      React.MouseEventHandler<HTMLElement>,
}

export const FaButton = (props: IFaButtonProps) => {
  const tooltip        = props.tooltip  || '';
  const htmlType       = props.htmlType || 'button';
  const rightIconStyle = {marginLeft: "8px", marginRight: "0px"};
  const danger         = props.danger || false;
  const block          = props.block || false;

  let float: any = "right";
  let left = null;
  let right = null;

  if (props.iconSide === undefined || props.iconSide === "left") {
    left = (<FaIcon icon={props.icon} set={props.set}/>)
  }

  if(props.iconSide !== undefined && props.iconSide === "right") {
    right = (<FaIcon icon={props.icon} set={props.set} style={rightIconStyle}/>)
    float = "left"
  }

  return (
    <Button
      className={props.className}
      id={props.id}
      htmlType={htmlType}
      type={props.button}
      size={props.size}
      disabled={props.disabled}
      onClick={props.onClick}
      title={tooltip}
      style={props.style}
      danger={danger}
      block={block}
      shape={props.shape}
      loading={props.loading}
    >
      {left}
      <span style={{float: float}}>
        {props.text}
      </span>
      {right}
    </Button>
  );
};

FaButton.defaultProps = {
  tooltip:  "",
  htmlType: "button",
  iconSide: "left"
};
