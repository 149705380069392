import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import Cookies from 'js-cookie';

import {DESIMAL_SESSION_TOKEN_COOKIE} from "../consts";
import {
  LoginSetSessionTokenAction,
  LoginSetStateMachineStateAction,
  LoginStateMachineStates,
  useSessionSelector
} from "../store";

export const CookieLoader: React.FC = (props) => {
  const dispatch=useDispatch();
  const session=useSessionSelector(state=>state.session);

  const [firstPass,setFirstPass]=useState(true);
  let token=session.sessionToken;

  if(firstPass){
    setFirstPass(false);
    if(!token) {
      const newToken = Cookies.get(DESIMAL_SESSION_TOKEN_COOKIE);
      if (newToken){
        dispatch(LoginSetSessionTokenAction(newToken));
        dispatch(LoginSetStateMachineStateAction(LoginStateMachineStates.LOGGED_IN));
      }
    }
  }

  return <React.Fragment>
    {props.children}
  </React.Fragment>;
}
