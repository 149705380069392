// imports
import * as React from 'react';
import {useContext, useEffect, useState} from "react";

// local imports
import {IWhizzzaaardPage, WhizzzaaardContext} from "./Whizzzaaard";
import {Col, Row} from "antd";
import {Grid} from "../utilities";

// types
export interface IWhizzzaaardPageProps extends IWhizzzaaardPage {
  children?: React.ReactNode,
}

export interface IWhizzzaaardPageContext {
  pageNumber: number,
}

export const WhizzzaaardPageContext = React.createContext<IWhizzzaaardPageContext>({pageNumber: 0});

// component
export const WhizzzaaardPage = (props: IWhizzzaaardPageProps): React.ReactElement | null => {
  const [visible, setVisible] = useState(false);

  const context = useContext(WhizzzaaardContext);

  useEffect(() => {
    setVisible(context.activePageNumber === props.pageNumber);
  }, [context.activePageNumber, props.pageNumber]);

  if (!visible) {
    return null;
  }

  return (
    <Row>
      <Col {...Grid.Full}>
        <WhizzzaaardPageContext.Provider value={{pageNumber: props.pageNumber}}>
          {props.children}
        </WhizzzaaardPageContext.Provider>
      </Col>
    </Row>
  );
};
