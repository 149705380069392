import React from 'react';
import * as Yup from 'yup';
import {Formik, FormikHelpers} from "formik";
import {Form, Input, SubmitButton} from 'formik-antd';
import QRCode from "qrcode.react";

import {generateTotp} from '../../utils';

export interface ITwoFAFormValues {
  token: string
}

const FormSchema=Yup.object().shape({
  token: Yup.string().matches(/^[0-9]{6}$/, "Must be exactly 6 digits")
});

interface ITwoFAFormProps {
  onSubmit: (values: ITwoFAFormValues, actions:FormikHelpers<ITwoFAFormValues>)=>void
  isLoading: boolean
  qrCodeUri?: string
}

export const TwoFAForm: React.FC<ITwoFAFormProps> = (props) => {
  const secret='6YUMTHAWP7YOSZCZ';
  console.log(generateTotp(secret));

  return (
      <Formik
        onSubmit={props.onSubmit}
        validationSchema={FormSchema}
        initialValues={{token: ""}}
      >
        <Form>
          {props.qrCodeUri
              ? <Form.Item name={"qrcode"} style={{textAlign: "center"}}>
                  <QRCode value={props.qrCodeUri}/>
                </Form.Item>
              : null
          }
          <Form.Item name={"token"}>
            <Input
              name={"token"}
              placeholder={"XXXXXX"}
              size={"large"}
              style={{textAlign: "center"}}
              />
          </Form.Item>
          <Form.Item name={"submit"}>
            <SubmitButton
                type={"primary"}
                htmlType={"submit"}
                style={{width: "100%"}}>
              Submit
            </SubmitButton>
          </Form.Item>
        </Form>
      </Formik>
  );
}
