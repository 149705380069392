// imports
import React from 'react';
import {Route,Redirect,RouteProps} from 'react-router-dom';

export type LoggedInCallback= () => boolean;

interface PrivateRouteProps extends RouteProps{
  loggedIn: LoggedInCallback,
}

export const PrivateRoute=(props: PrivateRouteProps)=>{
  const {loggedIn, component, ...rest} = props;

  const renderer=(routeProps: RouteProps): React.ReactNode|undefined => (
    loggedIn && loggedIn() && component
      ? React.createElement(component, routeProps)
      : <Redirect to={{pathname: '/login', state: {from: routeProps.location} }} />
  );

  return <Route {...rest} render={renderer} />;
};