import React from 'react';
import * as Yup from 'yup';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {Form, Input, Checkbox, SubmitButton} from 'formik-antd';
import {Formik,FormikHelpers} from 'formik';

import './LoginPage.less';

export interface ILoginFormValues {
  username: string
  password: string
}

const FormSchema=Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required")
});

interface ILoginFormProps {
  onSubmit: (values: ILoginFormValues, actions:FormikHelpers<ILoginFormValues>)=>void
  isLoading: boolean
}

export const LoginForm: React.FC<ILoginFormProps> = (props) => {
  return (
      <Formik
          onSubmit={props.onSubmit}
          validationSchema={FormSchema}
          initialValues={{username:"", password:""}}
      >
        <Form className={"login-form"}>

          <Form.Item name={"username"}>
            <Input
                name={"username"}
                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                placeholder={"Username"}
            />
          </Form.Item>
          <Form.Item name={"username"}>
            <Input
                name={"password"}
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                type={"password"}
                placeholder={"Password"}
            />
          </Form.Item>
          <Form.Item name={"remember"}>
            <Checkbox name={"remember"}>Remember me</Checkbox>
            <a className="login-form-forgot" href="/">Forgot password</a>
            <SubmitButton
                loading={props.isLoading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{width: "100%"}}>
              Log In
            </SubmitButton>
          </Form.Item>
        </Form>
      </Formik>
  )
};
