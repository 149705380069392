import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";

import {LogoutAction} from "../../store";

export const LogoutPage: React.FC =() => {
  const dispatch=useDispatch();

  useEffect(()=>{
    const logout=async()=>{
      await dispatch(LogoutAction());
    }
    logout();
  }, [dispatch]);

  return <div/>;
}
