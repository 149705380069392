import {generateHotp} from "./hotp";

export const generateTotp=(secret:string, window:number=0, timeStepInSecs:number=30,
                           initialTime:number=0, optLength:number=6)=>{
  if(!secret) throw new Error('Secret is required');

  const currentTime=Date.now();
  const timeStep=timeStepInSecs*1000;
  const counter=Math.floor((currentTime-initialTime)/timeStep);
  return generateHotp(secret, counter+window, optLength);
}

export const verifyTotp=(token:any, secret:string, window:number=1)=>{
  try {
    token=parseInt(token,10);
    if(isNaN(token)) throw new Error();
  } catch(e) {
    console.error('Invalid token.');
    return false;
  }

  if(Math.abs(+window)>10){
    console.error('Window size is too large');
    return false;
  }

  for(let errorWindow=-window; errorWindow<=+window; errorWindow++){
    const totp=generateTotp(secret, errorWindow);
    if(token===totp) return true;
  }

  return false;
}
