import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';

const _ScrollToTop: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, [history.location.pathname]);
  return null;
};

export const ScrollToTop = withRouter(_ScrollToTop);
