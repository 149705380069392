import {
  Card,
  Col,
  Switch
}                    from "antd";
import React         from "react";
import {Grid}        from "../utilities";
import {IconHeading} from "..";

interface IEditableCardProps {
  title: string,
  icon: string,
  value?: boolean,
  onToggle?: (value: boolean) => void,
  disableSwitch?: boolean,
  inCol?: boolean,
}

export const EditableCard = (props: React.PropsWithChildren<IEditableCardProps>) => {

  // Note: The checked value on the toggle is the inverse of editable (i.e. props.value)
  const resolveInverseForCallback = (value: boolean) => {
    if(props.onToggle){
      props.onToggle(!value);
    }
  };

  const title = <IconHeading text={props.title} icon={props.icon}/>;
  const editToggle = props.disableSwitch ? null:
    <Switch className={"edit-toggle"} checked={!props.value} size={"small"} onChange={resolveInverseForCallback} />;

  const card = (
    <Card title={title} bordered={false} extra={editToggle}>
      {props.children || null}
    </Card>
  );

  if (props.inCol) {
    return (
      <Col {...Grid.InnerResponsiveColumns}>
        {card}
      </Col>
    )
  }
  else {
    return (card)
  }
};

EditableCard.defaultProps = {inCol: true};
