import React from "react";
import {Card, Col, Layout, Row} from "antd";

import {ISignUpFormValues, SignUpForm} from "./SignUpForm";
import {SignUpAction, TTDispatch, useSignUpSelector, useSharedSelector} from "../../store";
import {useDispatch} from "react-redux";
import {FormikHelpers} from "formik";

export const SignUpPage: React.FC = (props) => {
  const dispatch=useDispatch<TTDispatch>();
  const signUp=useSignUpSelector(state=>state.signUp);
  const shared=useSharedSelector(state=>state.shared);

  const onSubmit = (values: ISignUpFormValues, actions: FormikHelpers<ISignUpFormValues>) => {
    const {username,firstName,lastName,email}=values;
    actions.setSubmitting(false);
    dispatch(SignUpAction(username,firstName,lastName,email)).catch(error=>{
      console.error("Sign Up Error:", error);
    });
  };

  return (
      <Layout className={"login-page-layout"}>
        <Layout.Content>
          <Row className={"row"} justify={"center"} align={"middle"} gutter={16}>
            <Col style={{maxWidth: "350px"}}>
              <Card cover={
                <img src={require("../../assets/images/desimal3.png")}
                     className={"logo"}
                     alt={"desimal logo"}/>
              }>
                { signUp.activationSent
                    ? <div/>
                    : <SignUpForm onSubmit={onSubmit} isLoading={shared.isFetching}/>
                }
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
  );
};
