import {push} from 'connected-react-router';
import axios from 'axios';
import Cookies from 'js-cookie';

import urls from '../../urls';

import {
  ILoginSet2FASessionTokenAction,
  ILoginSetFetchingSessionTokenAction,
  ILoginSetSessionTokenAction,
  ILoginSetStateMachineStateAction,
  LoginActionType,
  LoginStateMachineStates
} from "./types";
import {DESIMAL_SESSION_TOKEN_COOKIE} from "../../consts";
import {UserSetUserIdAction, TTResult, UserSetForcePasswordChangeAction} from "..";

export const LoginSetSessionTokenAction=(sessionToken?:string|undefined): ILoginSetSessionTokenAction=>{
  if(sessionToken) Cookies.set(DESIMAL_SESSION_TOKEN_COOKIE, sessionToken);
  else Cookies.remove(DESIMAL_SESSION_TOKEN_COOKIE);
  return {type: LoginActionType.LOGIN_SET_SESSION_TOKEN, sessionToken};
}

export const LoginSetFetchingSessionTokenAction=(isFetching:boolean): ILoginSetFetchingSessionTokenAction=>{
  return {type: LoginActionType.LOGIN_SET_FETCHING_SESSION_TOKEN, isFetching};
}

export const LoginSet2FASessionTokenAction=(is2FA:boolean): ILoginSet2FASessionTokenAction=>{
  return {type: LoginActionType.LOGIN_SET_2FA_SESSION_TOKEN, is2FA};
}

export const LoginSetStateMachineStateAction=(state:LoginStateMachineStates): ILoginSetStateMachineStateAction=>{
  return {type: LoginActionType.LOGIN_SET_STATE_MACHINE_STATE, state};
}

export const LoginAction=(username: string, password:string, next?:string, otp?:string):
    TTResult<Promise<any>> => (dispatch, getState) => {
  return new Promise<any>(async(resolve,reject)=> {
    dispatch(LoginSetStateMachineStateAction(LoginStateMachineStates.LOGGED_OUT));
    dispatch(LoginSetFetchingSessionTokenAction(true));
    let data = {username, password};
    if (otp) data['otp'] = otp;

    if (!next) {
      const state = getState()
      const query = state.router.location['query'];
      if (query && query['next']) next = query['next'];
    }

    try {
      let response = await axios.post(urls.SESSION_TOKEN_URL, data);
      dispatch(LoginSetSessionTokenAction(response.data['token']));
      dispatch(UserSetUserIdAction(response.data['userId']));
      dispatch(LoginSet2FASessionTokenAction(false));

      if (response.data['forcePasswordChange']) {
        dispatch(UserSetForcePasswordChangeAction(true));
        dispatch(LoginSetStateMachineStateAction(LoginStateMachineStates.CHANGE_PASSWORD));
      } else {
        dispatch(LoginSetStateMachineStateAction(LoginStateMachineStates.LOGGED_IN));
        dispatch(push(next || "/"));
      }
      resolve();
    } catch (error) {
      const code = error.response.status;
      if (code === 403 && error.response.data['detail'] === '2fa-required') {
        dispatch(LoginSet2FASessionTokenAction(true));
        dispatch(LoginSetStateMachineStateAction(LoginStateMachineStates.REQUEST_2FA));
        resolve();
      } else reject(error);
    } finally {
      dispatch(LoginSetFetchingSessionTokenAction(false));
    }
  });
}

export const LogoutAction=(): any => {
  return async(dispatch: any) => {
    dispatch(LoginSetSessionTokenAction());
    dispatch(UserSetUserIdAction());
    dispatch(LoginSetStateMachineStateAction(LoginStateMachineStates.LOGGED_OUT));
    return dispatch(push('/'));
  }
}
