const REST_URL_BASE=process.env.REACT_APP_API_ROOT || "http://localhost:8000/";
const AUTHENTICATION_URL_BASE=`${REST_URL_BASE}authentication/`;
const USERS_URL_BASE=`${AUTHENTICATION_URL_BASE}users/`

export default {
  // Session
  SESSION_TOKEN_URL: `${AUTHENTICATION_URL_BASE}session/`,
  AUTHORIZE_TOKEN_URL: `${AUTHENTICATION_URL_BASE}authorize/`,
  SIGN_UP_URL: `${AUTHENTICATION_URL_BASE}signup/`,
  ACTIVATE_URL: `${AUTHENTICATION_URL_BASE}activate/`,

  // Users
  USER_PROFILE_URL: (userId?:string) => {
    if (userId) return `${USERS_URL_BASE}${userId}/`;
    else return `${USERS_URL_BASE}current/`;
  },
  SET_PASSWORD_URL: (userId:string) => `${USERS_URL_BASE}${userId}/set_password/`,
}
