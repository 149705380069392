import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useDispatch} from "react-redux";
import queryString from 'query-string';

import {TTDispatch, SignUpActivateAction, useSignUpSelector} from "../../store";

export const ActivatePage: React.FC<RouteComponentProps> = (props) => {
  const dispatch=useDispatch<TTDispatch>();
  const signUp=useSignUpSelector(state=>state.signUp);
  const search=props.location.search;

  useEffect(()=>{
    const activate=()=>{
      const queryValues = queryString.parse(search);
      const {uidb64,token}=queryValues;
      if(typeof uidb64==="string" && typeof token==="string"){
        dispatch(SignUpActivateAction(uidb64,token)).catch(error=>{
          console.error("Activate Error:",error);
        });
      } else {
        console.error("Invalid query string types:", queryValues);
      }
    }
    activate();
  }, [search,dispatch]);

  return (
      <div>
      { signUp.tempPasswordSent
        ? <h1>Password email sent...</h1>
        : <h1>Activating...</h1>
      }
      </div>
  );
}
