import Cookies from "js-cookie";
import {DESIMAL_SESSION_TOKEN_COOKIE} from "../../consts";
import {SessionTokenExpiredError} from "./types";
import axios from "axios";
import urls from "../../urls";

export async function getAuthToken(): Promise<string> {
  let auth_token=sessionStorage.getItem("USER_TOKEN");
  if(auth_token) return auth_token;

  const session_token=Cookies.get(DESIMAL_SESSION_TOKEN_COOKIE);
  if(!session_token)
    throw new SessionTokenExpiredError("");

  try {
    const response=await axios.post(urls.AUTHORIZE_TOKEN_URL, null, {
      headers: {"Authorization": `JWT ${session_token}`}
    });
    auth_token=response.data.token;
    if(auth_token) sessionStorage.setItem("USER_TOKEN", auth_token);
    return auth_token||"";
  } catch(error) {
    console.error(error);
    throw new SessionTokenExpiredError("");
  }
}
