// imports
import * as React from 'react';
// local imports
import {IIconHeadingProps} from "./IconHeading";
import {FaIcon} from "..";

// component
export const IconH4 = (props: IIconHeadingProps): React.ReactElement => {
  return (
    <h4><FaIcon icon={props.icon} set={props.set}/>{props.text}</h4>
  );
};

