import axios from 'axios';

import {AuthTokenExpiredError} from "./types";
import {getAuthToken} from "./token";

const buildApiCall=(url: string)=>{
  return async(token: string)=>{
    try {
      return await axios.get(url, {headers: {"Authorization": `JWT ${token}`}});
    } catch(error) {
      const res=error.response;
      if(res.status===401&&res.data.detail==="Signature has expired.") {
        sessionStorage.removeItem("USER_TOKEN");
        throw new AuthTokenExpiredError("");
      }
      throw error;
    }
  }
}

export const get=async(url: string): Promise<any>=>{
  const apiCall=buildApiCall(url);

  let attempts=2;
  while(attempts>0){
    let auth_token = await getAuthToken();
    try {
      return await apiCall(auth_token);
    } catch (error) {
      if (!(error instanceof AuthTokenExpiredError)) throw error;
    }
    attempts -= 1;
  }
  throw new Error();
}
