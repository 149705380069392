// imports
import * as React from 'react';

// local imports
import {FaButton, FaSet} from "..";

// types
interface ICancelButtonProps {
  onCancel?: VoidFunction,
}

// component
export const CancelButton = (props: ICancelButtonProps): React.ReactElement => {
  return (
    <FaButton
      icon={"times"}
      onClick={props.onCancel}
      text={"Cancel"}
      set={FaSet.R}
    />
  );
};
