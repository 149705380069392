import * as React from 'react';
import * as _ from 'lodash';

import {When} from './When';

interface ChooseProps {
  children: Array<React.ReactElement<When>>,
}

export class Choose extends React.Component<ChooseProps> {
  public render(){
    return _.filter(this.props.children, (child: When) => child.props.condition);
  }
}