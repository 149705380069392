import {
  IUserSetForcePasswordChangeAction,
  IUserSetUserIdAction,
  IUserSetUserProfileAction,
  TTResult,
  UserActionType,
  UserProfile
} from "..";
import {push} from "connected-react-router";

import {getAction, postAction} from "../utils";
import urls from '../../urls';

export const UserSetUserIdAction=(userId?:string|undefined): IUserSetUserIdAction=>{
  return {type: UserActionType.USER_SET_USER_ID, userId};
}

export const UserSetForcePasswordChangeAction=(forcePasswordChange:boolean): IUserSetForcePasswordChangeAction=>{
  return {type: UserActionType.USER_SET_FORCE_PASSWORD_CHANGE, forcePasswordChange};
}

export const UserChangePasswordAction=(userId: string, password: string):
    TTResult<Promise<any>> => async(dispatch, getState) => {
  const payload={password};
  const continuation=(data: any, dispatch: any)=>{
    dispatch(UserSetForcePasswordChangeAction(false));
    dispatch(push('/'));
    return { success: true };
  }
  return await postAction(
      urls.SET_PASSWORD_URL(userId),
      payload,
      continuation,
      (content)=>true,
      "PUT"
  )(dispatch,getState);
}

export const UserGetProfileAction=(userId?: string):
    TTResult<Promise<any>> => async(dispatch,getState) => {
  const continuation=(data: any, dispatch: any)=>{
    const {pk, username, email, firstName, lastName, twoFAEnabled}=data.data
    dispatch(UserSetUserIdAction(pk));
    dispatch(UserSetProfileAction({username, email, firstName, lastName, twoFAEnabled}))
    return { success: true, result: data };
  }
  return await getAction(
      urls.USER_PROFILE_URL(userId),
      continuation
  )(dispatch,getState);
}

export const UserSetProfileAction=(profile?: UserProfile): IUserSetUserProfileAction => {
  return {type: UserActionType.USER_SET_USER_PROFILE, profile};
}
