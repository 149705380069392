import {createMemoryHistory} from "history";

export type CurriedTestAction<TPartialState extends {}, TAction extends {}> = (
  makeCurrent: StateMakerFunc<TPartialState>,
  makeExpected: StateMakerFunc<TPartialState>,
  action: TAction) => void;
export type ReducerFunc<TState extends {}, TAction> = (currentState: TState, action: TAction) => TState;
export type StateMakerFunc<TPartialState extends {}> = (partial: TPartialState) => TPartialState
export type StateFunc<TState extends {}, TPartialState extends {}> = (makePartial: StateMakerFunc<TPartialState>) => TState;

export function testAction<TState, TPartialState, TReducer, TAction>(
  stateMaker: StateFunc<TState, TPartialState>,
  makeCurrent: StateMakerFunc<TPartialState>,
  makeExpected: StateMakerFunc<TPartialState>,
  reducer: ReducerFunc<TState, TAction>,
  action: TAction): void {
  const currentState = stateMaker(makeCurrent);
  const expectedState = stateMaker(makeExpected);
  const nextState = reducer(currentState, action);

  expect(nextState).toEqual(expectedState);
}

export function partiallyAppliedTestAction<TState, TPartialState, TReducer, TAction>(
  stateMaker: StateFunc<TState, TPartialState>,
  reducer: ReducerFunc<TState, TAction>): CurriedTestAction<TPartialState, TAction> {
  return (makeCurrent: StateMakerFunc<TPartialState>, makeExpected: StateMakerFunc<TPartialState>, action: TAction) =>
    testAction(stateMaker, makeCurrent, makeExpected, reducer, action);
}

export const history = createMemoryHistory();

export function defineWindowMatchMediaProperty() {
  const MATCH_MEDIA = "matchMedia";

  if (window.hasOwnProperty(MATCH_MEDIA)) return;

  Object.defineProperty(window, MATCH_MEDIA, {
    writable: true,
    value: jest.fn().mockImplementation(query => ({
      matches: false,
      media: query,
      onchange: null,
      addListener: jest.fn(), // deprecated
      removeListener: jest.fn(), // deprecated
      addEventListener: jest.fn(),
      removeEventListener: jest.fn(),
      dispatchEvent: jest.fn(),
    })),
  });
}