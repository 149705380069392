import produce from "immer";
import {IUserAction, IUserReducer, UserActionType} from "./types";

export const UserReducer=(
    state: IUserReducer={
      forcePasswordChange: false,
    },
    action: IUserAction): IUserReducer =>
  produce(state, (draft: IUserReducer)=>{
    switch(action.type){
      case UserActionType.USER_SET_USER_ID:
        return {...state, userId: action.userId}
      case UserActionType.USER_SET_FORCE_PASSWORD_CHANGE:
        return {...state, forcePasswordChange: action.forcePasswordChange};
      case UserActionType.USER_SET_USER_PROFILE:
        return {...state, profile: action.profile}
      default: return state;
    }
  }
);
