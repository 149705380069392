import produce from 'immer';
import {ISignUpAction, ISignUpReducer, SignUpActionType} from "./types";

export const SignUpReducer=(
    state: ISignUpReducer={
      activationSent: false,
      tempPasswordSent: false,
    },
    action: ISignUpAction): ISignUpReducer =>
  produce(state, (draft: ISignUpReducer)=>{
    switch(action.type){
      case SignUpActionType.SIGN_UP_SET_ACTIVATION_SENT:
        return {...state, activationSent: action.activationSent};
      case SignUpActionType.SIGN_UP_SET_TEMP_PASSWORD_SENT:
        return {...state, tempPasswordSent: action.tempPasswordSent};
      default: return state;
    }
  }
);
