// imports
import * as React from 'react';
import { Row, Col } from 'antd';

// local imports
import {Grid, IAntDGrid, IconH4, IconH5, IconH6, IconHeading, IIconHeadingProps} from "..";

// types
export type HeadingType = "H3" | "H4" | "H5" | "H6";

export interface IDataHeadingProps extends IIconHeadingProps {
  h?:          HeadingType,
  extra?:      React.ReactNode,
  children?:   React.ReactNode,
  headingCol?: IAntDGrid,
  extraCol?:   IAntDGrid,
}

const propsToIconHeading = (props: IDataHeadingProps): React.ReactNode => {
  const h = props.h ?? "H5";

  switch (h) {
    case "H3":
      return <IconHeading {...props}/>;
    case "H4":
      return <IconH4 {...props} />;
    case "H5":
      return <IconH5 {...props} />;
    case "H6":
      return <IconH6 {...props} />
  }
};

// component
export const DataHeading = (props: IDataHeadingProps): React.ReactElement => {
  const heading = propsToIconHeading(props);

  const col = props.headingCol ?? Grid.Full;

  let extra = null

  if (props.extra) {
    extra = (
      <Col {...props.extraCol}>
        <span style={{float:"right"}}>
          {props.extra}
        </span>
      </Col>
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col {...col}>
          {heading}
        </Col>
        {extra}
      </Row>
      <Row>
        <Col {...Grid.Full}>
          {props.children}
        </Col>
      </Row>
    </React.Fragment>
  );
};

// [optional] connect/createForm