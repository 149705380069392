import {RootState, TypeSelectorHook} from "..";
import {useSelector as useReduxSelector} from "react-redux";

export enum SignUpActionType {
  SIGN_UP_SET_ACTIVATION_SENT="SIGN_UP_SET_ACTIVATION_SENT",
  SIGN_UP_SET_TEMP_PASSWORD_SENT="SIGN_UP_SET_TEMP_PASSWORD_SENT",
}

// Actions

export interface ISignUpSetActivationSentAction {
  type: SignUpActionType.SIGN_UP_SET_ACTIVATION_SENT
  activationSent: boolean
}

export interface ISignUpSetTempPasswordSentAction {
  type: SignUpActionType.SIGN_UP_SET_TEMP_PASSWORD_SENT
  tempPasswordSent: boolean
}

export type ISignUpAction =
  ISignUpSetActivationSentAction |
  ISignUpSetTempPasswordSentAction
;

// Reducers

export interface ISignUpReducer {
  activationSent: boolean
  tempPasswordSent: boolean
}

// Selectors

export const useSignUpSelector: TypeSelectorHook<RootState,ISignUpReducer> = useReduxSelector;
