import {RootState, TypeSelectorHook} from "..";
import {useSelector as useReduxSelector} from "react-redux";

export enum SharedActionType {
  SHARED_SET_API_FETCHING="SHARED_SET_API_FETCHING"
}

// Actions

export interface ISharedSetApiFetching {
  type: SharedActionType.SHARED_SET_API_FETCHING
  isFetching: boolean
}

export type ISharedAction =
    ISharedSetApiFetching;

// Reducers

export interface ISharedReducer {
  isFetching: boolean
}

// Selectors

export const useSharedSelector: TypeSelectorHook<RootState,ISharedReducer> = useReduxSelector;
