import React from 'react';
import * as Yup from 'yup';
import {Formik, FormikHelpers} from "formik";
import {Form, Input, SubmitButton} from 'formik-antd';
import {LockOutlined} from "@ant-design/icons";

export interface IChangePasswordFormValues {
  password: string
  confirm: string
}

const FormSchema=Yup.object().shape({
  password: Yup.string()
      .required("Required")
      .min(8, "Password is too short")
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Password must contain at least one uppercase character, one number and one of the following symbols: !@#$%^&*"
      ),
  confirm: Yup.string()
      .oneOf(
          [Yup.ref('password'),null],
          "Passwords must match"
      )
});

export interface IChangePasswordFormProps {
  onSubmit: (values: IChangePasswordFormValues, actions: FormikHelpers<IChangePasswordFormValues>)=>void
  isLoading: boolean
}

export const ChangePasswordForm: React.FC<IChangePasswordFormProps> = (props) => {
  return (
      <Formik
        onSubmit={props.onSubmit}
        validationSchema={FormSchema}
        initialValues={{password:"", confirm:""}}
      >
        <Form>
          <Form.Item name={"password"}>
            <Input
                name={"password"}
                type={"password"}
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder={"New password"}
            />
          </Form.Item>
          <Form.Item name={"confirm"}>
            <Input
                name={"confirm"}
                type={"password"}
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder={"Confirm password"}
            />
          </Form.Item>
          <Form.Item name={"submit"}>
            <SubmitButton
                type={"primary"}
                htmlType={"submit"}
                style={{width: "100%"}}>
              Submit
            </SubmitButton>
          </Form.Item>
        </Form>
      </Formik>
  );
}
