import * as React      from 'react';
import {FaIcon, FaSet} from "..";

export interface IIconHeadingProps {
  text: string,
  icon: string,
  set?: FaSet,
}

export const IconHeading = (props: IIconHeadingProps) => {
  return (
    <h3><FaIcon icon={props.icon} set={props.set}/>{props.text}</h3>
  );
};

