import axios from 'axios';

import {AuthTokenExpiredError, PostMethod} from "./types";
import {getAuthToken} from "./token";

const buildApiCall=(url: string, payload: any, method:PostMethod="POST")=>{
  return async(token: string)=>{
    let _method;
    switch(method){
      case "POST": _method=axios.post; break;
      case "PUT": _method=axios.put; break;
      default: throw new Error(`HTTP method "${method}" not implemented.`);
    }

    try {
      return await _method(url, payload, {headers: {"Authorization": `JWT ${token}`}});
    }
    catch(error) {
      const res=error.response;
      if(res.status===401&&res.data.detail==="Signature has expired.") {
        sessionStorage.removeItem("USER_TOKEN");
        throw new AuthTokenExpiredError("");
      }
      throw error;
    }
  }
}

export async function post(url:string, payload:any, method:PostMethod="POST"): Promise<any> {
  const apiCall = buildApiCall(url, payload, method);

  let attempts=2;
  while(attempts>0){
    let auth_token = await getAuthToken();
    try {
      return await apiCall(auth_token);
    } catch (error) {
      if (!(error instanceof AuthTokenExpiredError)) throw error;
    }
    attempts -= 1;
  }
  throw new Error();
}
