// imports
import * as React          from 'react';

// local imports
import {IIconHeadingProps} from "./IconHeading";
import {FaIcon}            from "..";

// component
export const IconH5 = (props: IIconHeadingProps): React.ReactElement => {
  return (
    <h5><FaIcon icon={props.icon} set={props.set}/>{props.text}</h5>
  );
};
