import {push} from "connected-react-router";
import {post} from "./post";
import {get} from "./get";
import {
  GetStateFunc,
  IPayload,
  IPayloadBuilderFunc,
  IContinuation,
  IPostSuccessfulFunc,
  PostMethod,
  Types,
  SessionTokenExpiredError
} from "./types";
import {store} from '../store';
import {SharedSetApiFetchingAction} from "../shared";

export function isTypeOf<T>(item: T, ...types: Types[]): boolean {
  for (const t of types) {
    if (typeof item === t) {
      return true
    }
  }
  return false
}

export function isPayloadBuilderFunc<TPayload>(f: IPayload<TPayload>): f is IPayloadBuilderFunc<TPayload> {
  return (isTypeOf(f, Types.FUNCTION));
}

export const postAction=<TResponse,TPayload>(
    url: string,
    payload: IPayload<TPayload>,
    continuation: IContinuation<TResponse>,
    postSuccessful: IPostSuccessfulFunc=(content)=>true,
    method:PostMethod="POST"
):any=>{
  return async(dispatch: any, getState: GetStateFunc)=>{
    return new Promise<any>(async(resolve,reject)=>{
      let _payload: TPayload;

      if(isPayloadBuilderFunc(payload)) _payload=payload(getState);
      else _payload=payload;

      dispatch(SharedSetApiFetchingAction(true));

      try {
        const content=await post(url, _payload, method);
        if(postSuccessful(content)){
          const result=continuation(content, dispatch);
          if(result.success) resolve(result.result);
          else reject(result.error);
        }
      } catch(error) {
        if(error instanceof SessionTokenExpiredError) {
          const state=store.getState();
          dispatch(push(`/login?next=${state.router.location.pathname}`));
          resolve();
        }
        reject(error);
      } finally {
        dispatch(SharedSetApiFetchingAction(false));
      }
    });
  }
}

export const getAction=<TResponse>(
    url: string,
    continuation: IContinuation<TResponse>
):any=>{
  return async(dispatch: any, getState: GetStateFunc)=>{
    return new Promise<any>(async(resolve,reject)=>{
      dispatch(SharedSetApiFetchingAction(true));
      try {
        const result=continuation(await get(url), dispatch);
        if(result.success) resolve(result.result);
        else reject(result.error);
      } catch (error) {
        if(error instanceof SessionTokenExpiredError) {
          const state=store.getState();
          dispatch(push(`/login?next=${state.router.location.pathname}`));
          resolve();
        }
        reject(error);
      } finally {
        dispatch(SharedSetApiFetchingAction(false));
      }
    });
  }
}
