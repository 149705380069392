import {useSelector as useReduxSelector} from "react-redux";
import {RootState} from "../store";
import {TypeSelectorHook} from "..";

export enum LoginStateMachineStates {
  LOGGED_OUT="LOGGED_OUT",
  REQUEST_2FA="REQUEST_2FA",
  LOGGED_IN="LOGGED_IN",
  CHANGE_PASSWORD="CHANGE_PASSWORD"
}

export enum LoginActionType {
  LOGIN_SET_SESSION_TOKEN="LOGIN_SET_SESSION_TOKEN",
  LOGIN_SET_FETCHING_SESSION_TOKEN="LOGIN_SET_FETCHING_SESSION_TOKEN",
  LOGIN_SET_2FA_SESSION_TOKEN="LOGIN_SET_2FA_SESSION_TOKEN",
  LOGIN_SET_STATE_MACHINE_STATE="LOGIN_SET_STATE_MACHINE_STATE",
}

// Actions

export interface ILoginSetSessionTokenAction {
  type: LoginActionType.LOGIN_SET_SESSION_TOKEN
  sessionToken: string|undefined
}

export interface ILoginSetFetchingSessionTokenAction {
  type: LoginActionType.LOGIN_SET_FETCHING_SESSION_TOKEN
  isFetching: boolean
}

export interface ILoginSet2FASessionTokenAction {
  type: LoginActionType.LOGIN_SET_2FA_SESSION_TOKEN
  is2FA: boolean
}



export interface ILoginSetStateMachineStateAction {
  type: LoginActionType.LOGIN_SET_STATE_MACHINE_STATE
  state: LoginStateMachineStates
}

export type ILoginSessionTokenAction =
    ILoginSetSessionTokenAction |
    ILoginSetFetchingSessionTokenAction |
    ILoginSet2FASessionTokenAction |
    ILoginSetStateMachineStateAction;

// Reducers

export interface ISessionTokenReducer {
  isFetching: boolean
  is2FA: boolean
  sessionToken?: string
  state: LoginStateMachineStates
}

// Selectors

export const useSessionSelector: TypeSelectorHook<RootState,ISessionTokenReducer> = useReduxSelector;
