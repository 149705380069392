import {store} from "../store";
import {LoginStateMachineStates} from "../session";

export * from './actions';
export * from './reducers';
export * from './types';

export const loggedIn=():boolean=>{
  const state=store.getState();
  return state.session.state===LoginStateMachineStates.LOGGED_IN;
}
