import {
  Card,
  Col
}                           from "antd";
import React                from "react";
import {
  RouteComponentProps,
  withRouter
}                           from "react-router";
import {
  FaAlignment,
  FaIcon,
  FaSet,
  Grid
} from "..";

import './ConsoleCard.less';

//Types
interface IConsoleCardProps {
  title: string,
  altText: string,
  helpText: string,
  icon: string,
  iconSet: FaSet,
  redirectTo: string,
  size?: number[],
  colour?: string,
}

const {Meta} = Card;

export const ConsoleCardComponent = (props: IConsoleCardProps & RouteComponentProps) => {

  const redirect = () => {
    props.history.push(props.redirectTo);
  };

  const image = (
    <div className='admin-console-image-wrapper'>
      <FaIcon
        icon={props.icon}
        set={props.iconSet}
        size="150px"
        colour={props.colour}
        className='admin-console-image'
        iconAlignment={FaAlignment.C}
      />
    </div>
  );

  return(
    <Col {...Grid.ResponsiveColumns} className='admin-console-col'>
      <Card hoverable size={'small'} cover={image} className='admin-scaled-card' bordered onClick={redirect}>
        <Meta title={props.title} description={props.helpText} />
      </Card>
    </Col>
  )
};

export const ConsoleCard = withRouter(ConsoleCardComponent);