// Imports
import {IAntDGrid} from "../types";

// Utils
const NO_GUTTER_SETTINGS = {xs: 0, sm: 0, md: 0, lg: 0, xl: 0};
const GUTTER_SETTINGS = {xs: 8, sm: 24, md: 24, lg: 24, xl: 24};
const RESPONSIVE_COLUMNS = {xs: 24, sm: 20, md: 12, lg: 10, xl: 8};
const LARGE_RESPONSIVE_COLUMNS = {xs: 24, sm: 20, md: 12, lg: 14, xl: 16};
const INNER_RESPONSIVE_COLUMNS = {xs: 24, sm: 24, md: 24, lg: 24, xl: 12};
const DESCRIPTIONS = {xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3};

const ONE_TWENTY_FOURTH = {xs: 6, sm: 6, md: 6, lg: 1, xl: 1};
const TWO_TWENTY_FOURTHS = {xs: 6, sm: 6, md: 6, lg: 2, xl: 2};
const THREE_TWENTY_FOURTHS = {xs: 6, sm: 6, md: 6, lg: 3, xl: 3};
const FOUR_TWENTY_FOURTHS = {xs: 6, sm: 6, md: 6, lg: 4, xl: 4};
const FIVE_TWENTY_FOURTHS = {xs: 6, sm: 6, md: 6, lg: 5, xl: 5};
const SIX_TWENTY_FOURTHS = {xs: 6, sm: 6, md: 6, lg: 6, xl: 6};
const SEVEN_TWENTY_FOURTHS = {xs: 12, sm: 12, md: 12, lg: 7, xl: 7};
const EIGHT_TWENTY_FOURTHS = {xs: 12, sm: 12, md: 12, lg: 8, xl: 8};
const NINE_TWENTY_FOURTHS = {xs: 12, sm: 12, md: 12, lg: 9, xl: 9};
const TEN_TWENTY_FOURTHS = {xs: 12, sm: 12, md: 12, lg: 10, xl: 10};
const ELEVEN_TWENTY_FOURTHS = {xs: 12, sm: 12, md: 12, lg: 11, xl: 11};
const TWELVE_TWENTY_FOURTHS = {xs: 12, sm: 12, md: 12, lg: 12, xl: 12};
const THIRTEEN_TWENTY_FOURTHS = {xs: 18, sm: 18, md: 18, lg: 13, xl: 13};
const FOURTEEN_TWENTY_FOURTHS = {xs: 18, sm: 18, md: 18, lg: 14, xl: 14};
const FIFTEEN_TWENTY_FOURTHS = {xs: 18, sm: 18, md: 18, lg: 15, xl: 15};
const SIXTEEN_TWENTY_FOURTHS = {xs: 18, sm: 18, md: 18, lg: 16, xl: 16};
const SEVENTEEN_TWENTY_FOURTHS = {xs: 18, sm: 18, md: 18, lg: 17, xl: 17};
const EIGHTEEN_TWENTY_FOURTHS = {xs: 18, sm: 18, md: 18, lg: 18, xl: 18};
const NINETEEN_TWENTY_FOURTHS = {xs: 24, sm: 24, md: 24, lg: 19, xl: 19};
const TWENTY_TWENTY_FOURTHS = {xs: 24, sm: 24, md: 24, lg: 20, xl: 20};
const TWENTY_ONE_TWENTY_FOURTHS = {xs: 24, sm: 24, md: 24, lg: 21, xl: 21};
const TWENTY_TWO_TWENTY_FOURTHS = {xs: 24, sm: 24, md: 24, lg: 22, xl: 22};
const TWENTY_THREE_TWENTY_FOURTHS = {xs: 24, sm: 24, md: 24, lg: 23, xl: 23};
const TWENTY_FOUR_TWENTY_FOURTHS = {xs: 24, sm: 24, md: 24, lg: 24, xl: 24};

export class Grid {
  public static get NoGutters(): IAntDGrid {return NO_GUTTER_SETTINGS}
  public static get Gutters(): IAntDGrid {return GUTTER_SETTINGS}

  public static get ResponsiveColumns(): IAntDGrid {return RESPONSIVE_COLUMNS}
  public static get LargeResponsiveColumns(): IAntDGrid {return LARGE_RESPONSIVE_COLUMNS}
  public static get InnerResponsiveColumns(): IAntDGrid {return INNER_RESPONSIVE_COLUMNS}

  public static get OneTwentyFourth(): IAntDGrid {return ONE_TWENTY_FOURTH}
  public static get TwoTwentyFourths(): IAntDGrid {return TWO_TWENTY_FOURTHS}
  public static get ThreeTwentyFourths(): IAntDGrid {return THREE_TWENTY_FOURTHS}
  public static get FourTwentyFourths(): IAntDGrid {return FOUR_TWENTY_FOURTHS}
  public static get FiveTwentyFourths(): IAntDGrid {return FIVE_TWENTY_FOURTHS}
  public static get SixTwentyFourths(): IAntDGrid {return SIX_TWENTY_FOURTHS}                  // One Quarter
  public static get OneQuarter(): IAntDGrid {return SIX_TWENTY_FOURTHS}
  public static get SevenTwentyFourths(): IAntDGrid {return SEVEN_TWENTY_FOURTHS}
  public static get EightTwentyFourths(): IAntDGrid {return EIGHT_TWENTY_FOURTHS}              // One Third
  public static get OneThird(): IAntDGrid {return EIGHT_TWENTY_FOURTHS}
  public static get NineTwentyFourths(): IAntDGrid {return NINE_TWENTY_FOURTHS}
  public static get TenTwentyFourths(): IAntDGrid {return TEN_TWENTY_FOURTHS}
  public static get ElevenTwentyFourths(): IAntDGrid {return ELEVEN_TWENTY_FOURTHS}
  public static get TwelveTwentyFourths(): IAntDGrid {return TWELVE_TWENTY_FOURTHS}            // Half
  public static get Half(): IAntDGrid {return  TWELVE_TWENTY_FOURTHS}
  public static get ThirteenTwentyFourths(): IAntDGrid {return THIRTEEN_TWENTY_FOURTHS}
  public static get FourteenTwentyFourths(): IAntDGrid {return FOURTEEN_TWENTY_FOURTHS}
  public static get FifteenTwentyFourths(): IAntDGrid {return FIFTEEN_TWENTY_FOURTHS}
  public static get SixteenTwentyFourths(): IAntDGrid {return SIXTEEN_TWENTY_FOURTHS}          // Two Thirds
  public static get TwoThirds(): IAntDGrid {return  SIXTEEN_TWENTY_FOURTHS;}
  public static get SeventeenTwentyFourths(): IAntDGrid {return SEVENTEEN_TWENTY_FOURTHS}
  public static get EighteenTwentyFourths(): IAntDGrid {return EIGHTEEN_TWENTY_FOURTHS}        // Three Quarters
  public static get ThreeQuarters(): IAntDGrid {return EIGHTEEN_TWENTY_FOURTHS}
  public static get NineteenTwentyFourths(): IAntDGrid {return NINETEEN_TWENTY_FOURTHS}
  public static get TwentyTwentyFourths(): IAntDGrid {return TWENTY_TWENTY_FOURTHS}
  public static get TwentyOneTwentyFourths(): IAntDGrid {return TWENTY_ONE_TWENTY_FOURTHS}
  public static get TwentyTwoTwentyFourths(): IAntDGrid {return TWENTY_TWO_TWENTY_FOURTHS}
  public static get TwentyThreeTwentyFourths(): IAntDGrid {return TWENTY_THREE_TWENTY_FOURTHS}
  public static get TwentyFourTwentyFourths(): IAntDGrid {return TWENTY_FOUR_TWENTY_FOURTHS}   // Full
  public static get Full(): IAntDGrid {return TWENTY_FOUR_TWENTY_FOURTHS}

  public static get descriptions(): IAntDGrid {return DESCRIPTIONS}
}
