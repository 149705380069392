// imports
import * as React from 'react';
import {FaIcon, FaSet} from '../FaIcon/FaIcon';
import {Button} from "antd";
import {ButtonShape} from "antd/lib/button";

// types
interface IEditButtonProps {
  id?:            string,
  onClick?:       React.MouseEventHandler<HTMLElement>,
  shape?:         ButtonShape,
  set?:           FaSet,
  leftPaddingPX?: number,
}

// component
export const CardEditButton = (props: IEditButtonProps): React.ReactElement => {
  const editIcon = (<FaIcon icon={"edit"} style={{paddingLeft: `${props.leftPaddingPX}px`}} set={props.set}/>);

  return (
    <Button id={props.id} htmlType={"button"} shape={props.shape} icon={editIcon} onClick={props.onClick}/>
  );
};

CardEditButton.defaultProps = {
  shape:         "circle",
  set:           FaSet.L,
  leftPaddingPX: 7,
};

