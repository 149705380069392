import {createStore, applyMiddleware, combineReducers} from "redux";
import {createHashHistory, History} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import thunk from "redux-thunk";

import {SharedReducer, ISharedReducer as SharedState} from "./shared";
import {LoginReducer, ISessionTokenReducer as SessionState} from "./session";
import {SignUpReducer, ISignUpReducer as SignUpState} from './signup';
import {UserReducer, IUserReducer as UserState} from "./users";

export interface RootState {
  shared: SharedState
  session: SessionState
  signUp: SignUpState
  users: UserState
}

const allReducers={
  shared: SharedReducer,
  session: LoginReducer,
  signUp: SignUpReducer,
  users: UserReducer
}
export const rootReducer=(history: History)=>combineReducers(
    {router: connectRouter(history), ...allReducers}
);

export const history=createHashHistory({hashType: "slash"});
export const store=createStore(
    rootReducer(history),
    applyMiddleware(routerMiddleware(history), thunk)
);

