import React, {useState} from 'react';
import {Card, Col, Layout, Row} from 'antd';
import {useDispatch} from "react-redux";
import {FormikHelpers} from "formik";
import {RouteComponentProps,Redirect} from 'react-router-dom'

import {ILoginFormValues, LoginForm} from "./LoginForm";
import {ITwoFAFormValues, TwoFAForm} from "../../components/TwoFAForm/TwoFAForm";
import {
  UserChangePasswordAction,
  LoginAction,
  loggedIn,
  TTDispatch,
  useSessionSelector, useUserSelector
} from "../../store";
import {ChangePasswordForm, IChangePasswordFormValues} from "./ChangePasswordForm";

import './LoginPage.less';

export const LoginPage: React.FC<RouteComponentProps> = (props) => {
  const dispatch=useDispatch<TTDispatch>();
  const session=useSessionSelector(state=>state.session);
  const users=useUserSelector(state=>state.users);

  const [{username,password},setCredentials]=useState({username:"", password:""});
  let next:string|undefined=undefined;
  if(props.location.state && props.location.state["from"]){
    next=props.location.state["from"].pathname;
  }

  const onLoginSubmit=(values: ILoginFormValues, actions: FormikHelpers<ILoginFormValues>)=>{
    const {username,password}=values;
    setCredentials({username,password});
    actions.setSubmitting(false);
    dispatch(LoginAction(username, password, next)).catch(error=>{
      console.error("Login Error:",error.response)
    });
  };

  const onTwoFASubmit=(values: ITwoFAFormValues, actions: FormikHelpers<ITwoFAFormValues>)=>{
    actions.setSubmitting(false);
    dispatch(LoginAction(username, password, next, values.token)).catch(error=>{
      console.error("Login Error (2FA):",error.response)
    });
  }

  const onChangePasswordSubmit=(values: IChangePasswordFormValues, actions: FormikHelpers<IChangePasswordFormValues>)=>{
    const {password}=values;
    actions.setSubmitting(false);
    if(users.userId)
      dispatch(UserChangePasswordAction(users.userId, password)).catch(error=>{
        console.error("Change Password Error:",error.response)
      });
  }

  let form;
  if(!session.isFetching && session.is2FA)
    form=<TwoFAForm onSubmit={onTwoFASubmit} isLoading={session.isFetching}/>;
  else if(!session.isFetching && users.forcePasswordChange)
    form=<ChangePasswordForm onSubmit={onChangePasswordSubmit} isLoading={session.isFetching} />;
  else form=<LoginForm onSubmit={onLoginSubmit} isLoading={session.isFetching}/>;

  if(loggedIn()) return <Redirect to={{pathname: "/"}} />;

  return (
    <Layout className={"login-page-layout"}>
      <Layout.Content>
        <Row className={"row"} justify={"center"} align={"middle"} gutter={16}>
          <Col style={{maxWidth: "350px"}}>
            <Card cover={
              <img src={require("../../assets/images/desimal3.png")}
                   className={"logo"}
                   alt={"desimal logo"}/>
            }>
              {form}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};
