import * as React from 'react';

import {MonadicVoidFunc} from "../types";

import './FaIcon.less'

export enum FaSet {
  B = "b",
  D = "d",
  L = "l",
  S = "s",
  R = "r",
}
  
export enum FaAlignment {
  L = 'left',
  R = 'right',
  C = 'centre'
}

interface FaIconProps {
  icon:           string,
  set?:           FaSet,
  colour?:        string,
  shadow?:        boolean,
  size?:          string,
  style?:         React.CSSProperties,
  className?:     string,
  iconAlignment?: FaAlignment,
  onClick?:       MonadicVoidFunc<React.MouseEvent>,
}

export const FaIcon = (props: FaIconProps) => {

  const getImageAlignment = () => {
    const alignmentText = props.iconAlignment || FaAlignment.L;

    switch (alignmentText) {
      case FaAlignment.C:
        return 'fontawesome-centre-align';
      case FaAlignment.R:
        return 'fontawesome-margin-left-10px';
      default:
        return 'fontawesome-margin-right-10px';
    }
  };

  const buildStyle = (prps: FaIconProps): React.CSSProperties => {
    const color      = "color";
    const fontSize   = "fontSize";
    const textShadow = "textShadow";

    let propsStyle: React.CSSProperties = {};

    if (prps.style !== undefined) {
      propsStyle = {...prps.style}
    }

    const result = {};

    if (prps.colour !== undefined && prps.colour) {
      result[color] = prps.colour;
    }

    if (prps.size !== undefined && prps.size) {
      result[fontSize] = prps.size;
    }

    if (prps.shadow !== undefined && prps.shadow) {
      result[textShadow] = "1px 1px 1px"
    }

    return {...result, ...propsStyle};
  };

  const set   = props.set || FaSet.S;

  const style = buildStyle(props);

  let cssClass = `${getImageAlignment()} fa${set} fa-${props.icon}`;

  if(props.className){
    cssClass = cssClass + ' ' + props.className;
  }

  return (
    <i style={style} className={cssClass} onClick={props.onClick}/>
  )
};