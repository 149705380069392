// imports
import * as React                                       from 'react';
import {useEffect, useState}                            from 'react';
import * as _                                           from 'lodash';
import {Card, Col, Row, Steps}                          from 'antd';

// local imports
import {IWhizzzaaardPageContext, IWhizzzaaardPageProps} from "./WhizzzaaardPage";
import {Grid}                                           from "../utilities";
import {FaButton}                                       from "..";
import {PredicateFunc, PredicateFuncAsync}              from "../types";

//consts
const Step = Steps.Step;

// types
export interface IWhizzzaaardPage {
  pageNumber:   number,
  title?: React.ReactNode,
  icon?:  React.ReactElement,
  onPrevious?: VoidFunction,
  onNext?: VoidFunction,
}

type Children = React.FunctionComponentElement<IWhizzzaaardPageProps>[] | React.FunctionComponentElement<IWhizzzaaardPageProps>;

interface IWhizzzaaardProps {
  startingPageNumber?: number,
  heading?: React.ReactNode,
  children?: Children,
  onFinished?: VoidFunction,
  extra?: string | React.ReactNode,
}

type HandlerRegisterFunc = (ctx: IWhizzzaaardPageContext, func: PredicateFunc | PredicateFuncAsync) => VoidFunction;

interface IWhizzzaaardContext {
  activePageNumber: number,
  registerNextHandler?: HandlerRegisterFunc,
  registerPrevHandler?: HandlerRegisterFunc,
}

const whizzzaaardCtx: IWhizzzaaardContext = {
  activePageNumber: 1,
}

const getPages = (children?: Children): IWhizzzaaardPage[] => {
  if (!children) return [];

  if(Array.isArray(children)) {
    return _.map(children, c => c.props)
  }

  return [children.props];
}

const startingPageIndex = (props: IWhizzzaaardProps): number => {
  const children           = props.children;
  const startingPageNumber = props.startingPageNumber ?? 1;

  if(!children) return -1;

  if(Array.isArray(children)) {
    return _.findIndex(children, c => c.props.pageNumber === startingPageNumber);
  }

  return 0;
}

export const WhizzzaaardContext = React.createContext(whizzzaaardCtx);

type PageHandlerRegister = {
  [pageNumber: number]: PredicateFunc | PredicateFuncAsync,
}

const renderActions = (
  currentPage:       number,
  numPages:          number,
  onNextHandler:     VoidFunction,
  onPreviousHandler: VoidFunction): React.ReactNode[] => {

  const prev   = <FaButton id={"whizzzaaardPreviousButton"} icon={"arrow-left"} text={"Previous"} onClick={onPreviousHandler} iconSide={"left"}/>;
  const next   = <FaButton id={"whizzzaaardNextButton"} icon={"arrow-right"} text={"Next"} onClick={onNextHandler} iconSide={"right"}/>;
  const finish = <FaButton id={"whizzzaaardFinishButton"} icon={"flag-checkered"} text={"Finish"} onClick={onNextHandler}/>

  if (currentPage === numPages - 1) {
    return [prev, finish]
  }

  return [prev, next];
}

// component
export const Whizzzaaard: React.FunctionComponent<IWhizzzaaardProps> = (props: IWhizzzaaardProps): React.ReactElement => {
  const [pages]                         = useState<IWhizzzaaardPage[]>(getPages(props.children));
  const [currentPage, setCurrentPage]   = useState<number>(startingPageIndex(props));
  const [steps, setSteps]               = useState<React.ReactNode>(null);
  const [contextValue, setContextValue] = useState<IWhizzzaaardContext>({activePageNumber: props.startingPageNumber ?? 1});
  const [nextHandlers, setNextHandlers] = useState<PageHandlerRegister>({});
  const [prevHandlers, setPrevHandlers] = useState<PageHandlerRegister>({});

  const registerNextHandler = (ctx: IWhizzzaaardPageContext, func: PredicateFunc | PredicateFuncAsync) => {
    const handlers = {...nextHandlers};

    handlers[ctx.pageNumber] = func;
    setNextHandlers(handlers);

    return () => {
      if (nextHandlers.hasOwnProperty(ctx.pageNumber)) {
        const handlers = {...nextHandlers}

        delete handlers[ctx.pageNumber];
        setNextHandlers(handlers);
      }
    }
  }

  const registerPrevHandler = (ctx: IWhizzzaaardPageContext, func: PredicateFunc | PredicateFuncAsync) => {
    const handlers = {...prevHandlers};

    handlers[ctx.pageNumber] = func;
    setPrevHandlers(handlers);

    return () => {
      if (prevHandlers.hasOwnProperty(ctx.pageNumber)) {
        const handlers = {...prevHandlers}

        delete handlers[ctx.pageNumber];
        setPrevHandlers(handlers);
      }
    }
  }

  const onNextHandler = async () => {
    async function canProceed(page: IWhizzzaaardPage): Promise<boolean> {
      if (nextHandlers.hasOwnProperty(page.pageNumber)) {
        return nextHandlers[page.pageNumber]();
      }

      return true;
    }

    if (pages.length === 0) return;

    const nextPage = currentPage + 1;

    if (nextPage < pages.length) {
      const page = pages[currentPage];

      if (await canProceed(page)) {
        page.onNext?.();
        setCurrentPage(nextPage);
      }
    }
    else {
      const page = pages[currentPage];

      if (await canProceed(page)) {
        page.onNext?.();
        props.onFinished?.();
      }
    }
  }

  const onPreviousHandler = async () => {
    if (pages.length === 0) return;

    const prevPage = currentPage - 1;

    if (prevPage >= 0) {
      const page = pages[currentPage];
      let canProceed = true;

      if (prevHandlers.hasOwnProperty(page.pageNumber)) {
        canProceed = await prevHandlers[page.pageNumber]();
      }

      if (canProceed) {
        pages[currentPage].onPrevious?.();
        setCurrentPage(currentPage - 1);
      }
    }
  }

  useEffect(() => {
    if (pages.length > 0) {
      const p = pages[currentPage];

      setContextValue({...whizzzaaardCtx, activePageNumber: p.pageNumber, registerNextHandler, registerPrevHandler});
    }
  }, [currentPage, pages])

  useEffect(() => {
    setSteps(_.map(pages, (p) => (<Step key={p.pageNumber} title={p.title} icon={p.icon}/>)));
  }, [pages]);

  const actions = renderActions(currentPage, pages.length, onNextHandler, onPreviousHandler);

  return (
    <Row>
      <Col {...Grid.Full}>
        <Card title={props.heading} actions={actions} extra={props.extra}>
          <WhizzzaaardContext.Provider value={contextValue}>
            <Row className={"study-border-bottom study-padding-bottom-16px"}>
              <Col {...Grid.Full}>
                <Steps current={currentPage}>
                  {steps}
                </Steps>
              </Col>
            </Row>
            <Row className={"study-padding-top-12px"}>
              <Col {...Grid.Full}>
                {props.children}
              </Col>
            </Row>
          </WhizzzaaardContext.Provider>
        </Card>
      </Col>
    </Row>
  );
};

Whizzzaaard.defaultProps = {
  startingPageNumber: 1,
}
