// imports
import React                        from 'react';

// local imports

import {
  Choose,
  EditableCard,
  ICanUpdateIfModel,
  ICanUpdateModel,
  IModel,
  IOptionalModel,
  When
} from "../index";

// Types

export interface IEditOnSwitchProps<T> extends ICanUpdateModel<T> {
  disableSwitch?: boolean,
  underEdit?: boolean,
  onEditToggle?: (value: boolean) => void,
  onEditComponent: React.ComponentType<ICanUpdateModel<T>> | React.ComponentType<ICanUpdateIfModel<T>>,
  onDisplayComponent: React.ComponentType<IModel<T>> | React.ComponentType<IOptionalModel<T>>,
  title: string,
  icon: string,
  inCol?: boolean,
}

export const EditOnSwitch = <T extends {}> (props: IEditOnSwitchProps<T>) => {

  const userIsEditing = props.underEdit !== undefined ? props.underEdit: false;

  const EditComponent = props.onEditComponent;
  const DisplayComponent = props.onDisplayComponent;

  const defaultDiscard = () => {
    if(props.onEditToggle){
      props.onEditToggle(false);
    }
  };

  return (
    <EditableCard
      title={props.title}
      icon={props.icon}
      value={props.underEdit}
      onToggle={props.onEditToggle}
      inCol={props.inCol}
      disableSwitch={props.disableSwitch}>
      <Choose>
        <When condition={userIsEditing}>
          <EditComponent model={props.model} onModelChange={props.onModelChange} onDiscardChanges={props.onDiscardChanges || defaultDiscard} />
        </When>
        <When condition={!userIsEditing}>
          <DisplayComponent model={props.model} />
        </When>
      </Choose>
    </EditableCard>
  );
};

EditOnSwitch.defaultProps = {
  inCol:     true,
  underEdit: false,
};