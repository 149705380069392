import * as crypto from 'crypto';
import * as base32 from 'hi-base32';

/* RFC 4226 */
export const generateHotp=(secret:string, counter:number, otpLength:number=6, hmacAlgorithm:string='sha1')=>{
  if(!secret) throw new Error('Secret is required');
  if(!counter) throw new Error('Counter is required');

  const decodedSecret=base32.decode.asBytes(secret);
  const buffer=Buffer.alloc(8);
  for(let i=0; i<8; i++){
    buffer[7-i]=counter&0xff;
    counter=counter>>8;
  }

  // Step 1: HMAC-SHA-1
  const hmac=crypto.createHmac(hmacAlgorithm, Buffer.from(decodedSecret));
  hmac.update(buffer);
  const hmacResult=hmac.digest();

  // Step 2: Generate a 4-byte string (dynamic truncation)
  const code=dynamicTruncation(hmacResult);

  // Step 3: Compute an HOTP value
  return code%10**otpLength;
}

const dynamicTruncation=(hmacValue:any)=>{
  const offset=hmacValue[hmacValue.length-1]&0xf;

  return (
      ((hmacValue[offset]&0x7f)<<24) |
      ((hmacValue[offset+1]&0xff)<<16) |
      ((hmacValue[offset+2]&0xff)<<8) |
      (hmacValue[offset+3]&0xff)
  );
}
