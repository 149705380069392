// Imports
import * as React                 from 'react';

export interface INoIconHeadingProps {
  text: string,
}

// Exported Const
export const NoIconHeading = (props: INoIconHeadingProps) => {
  return (
    <h3>{ props.text }</h3>
  );
};
