// imports
import * as React from 'react';
import {Col, Row} from "antd";
import {Grid}     from "../utilities";

// local imports

// constants

// types
interface IRowAndFullColProps {
  children?:  React.ReactNode,
  className?: string,
}

// component
export const RowAndFullCol = (props: IRowAndFullColProps): React.ReactElement => {
  return (
    <Row className={props.className}>
      <Col {...Grid.Full}>
        {props.children}
      </Col>
    </Row>
  );
};
