// imports
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Input, Menu} from 'antd';
import {MenuClickEventHandler, MenuInfo} from 'rc-menu/lib/interface'
// local imports
import {FaIcon, FaSet} from '..';

const Search = Input;

export const menuItem = (
  key:     string,
  title:   string,
  icon:    string,
  faIcon:  FaSet = FaSet.S,
  onClick: MenuClickEventHandler | undefined = undefined) => {
  return (
    <Menu.Item id={key} key={key} onClick={onClick}>
      <FaIcon icon={icon} set={faIcon}/>
      <span>{title}</span>
    </Menu.Item>
  );
};

interface DefaultDrawerMenuProps {
  search?: boolean,
  defaults?: boolean,
  profile: boolean,
}

const DefaultDrawerMenu: React.FC<RouteComponentProps<any> & DefaultDrawerMenuProps> = (props) => {
  const itemClicked = (event: MenuInfo) => {
    props.history.push(`/${event.key.toString().toLowerCase()}`);
  };

  let profile = null;

  if (props.profile) {
    profile = (
      <Menu.Item key={'profile'}>
        <FaIcon icon={'user-circle'} set={FaSet.R}/>
        <span>Profile</span>
      </Menu.Item>
    )
  }

  return (
    <Menu mode={'inline'} theme={'light'} onClick={itemClicked}>
      {props.search ?
        <Menu.Item>
          <Search placeholder={'Search...'} size={'small'}/>
        </Menu.Item>
        : null
      }
      {props.children}
      {profile}
    </Menu>
  );
};

DefaultDrawerMenu.defaultProps = {search: true, defaults: true, profile: true};

export const DrawerMenu = withRouter(DefaultDrawerMenu);
