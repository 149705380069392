// Imports
import { Layout } from 'antd';
import {default as classNames}           from "classnames";
import React, {useState}                 from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import './Drawer.less';
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons/lib";

// Constants
const {Sider}=Layout;

// Types
interface DefaultDrawerProps {logo: string}

// Component
const DefaultDrawer: React.FC<RouteComponentProps<any>&DefaultDrawerProps> = (props) => {
  const [open,setOpen]=useState(false);
  const siderClasses=classNames("drawer", {'open': open});
  const overlayClasses=classNames("drawer-overlay", {'show': open});
  const toggleDrawer=()=>setOpen(!open);

  return (
    <React.Fragment>
      <div className={overlayClasses} onClick={toggleDrawer} />
      <Sider className={siderClasses}>
        <div className={"open-button"} onClick={toggleDrawer}>
          {open ? <MenuFoldOutlined className={"open-button-icon"} /> : <MenuUnfoldOutlined className={"open-button-icon"} />}
        </div>
        <div className={"logo"}>
          <img src={props.logo} alt={""} />
        </div>
        {props.children}
      </Sider>
    </React.Fragment>
  );
};

export const Drawer = withRouter(DefaultDrawer);
