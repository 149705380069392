import React from 'react';
import {Breadcrumb as AntdBreadcrumb} from "antd";
import {Link} from "react-router-dom";

import {FaIcon, FaSet} from "../zoo";

import './Breadcrumb.less';

export interface IBreadcrumbRoute {
  path: string
  breadcrumbName: string
}

export interface IBreadcrumbProps {
  routes: IBreadcrumbRoute[]
}

export const Breadcrumb=(props: IBreadcrumbProps)=>{
  const itemRender=(route: any, params: any, routes: any[], paths: string[])=>{
    const last=routes.indexOf(route)===routes.length-1;
    return last? <span>{route.breadcrumbName}</span>:<Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
  };

  return (
      <div className={'breadcrumbElement'}>
        <FaIcon icon={'home'} set={FaSet.S} />
        <AntdBreadcrumb itemRender={itemRender} routes={props.routes} />
      </div>
  );
};
