import {ISharedAction, ISharedReducer, SharedActionType} from "./types";
import produce from "immer";

export const SharedReducer=(
    state: ISharedReducer={isFetching:false},
    action: ISharedAction): ISharedReducer =>
  produce(state, (draft: ISharedReducer)=>{
    switch(action.type){
      case SharedActionType.SHARED_SET_API_FETCHING:
        return {...state, isFetching: action.isFetching};
      default: return state;
    }
  }
);
