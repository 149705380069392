import {useContext, useEffect} from "react";
import {WhizzzaaardContext} from "./Whizzzaaard";
import {WhizzzaaardPageContext} from "./WhizzzaaardPage";
import {PredicateFunc, PredicateFuncAsync} from "../types";

export const useNotifyWhizzzaaardNext = (onNextHandler: PredicateFunc | PredicateFuncAsync) => {
  const pageContext = useContext(WhizzzaaardPageContext);
  const context = useContext(WhizzzaaardContext)

  useEffect(() => {
    if (context && pageContext) {
      context.registerNextHandler?.(pageContext, onNextHandler);
    }
  }, [context, pageContext, onNextHandler]);
}

export const useNotifyWhizzzaaardPrevious = (onPrevHandler: PredicateFunc) => {
  const pageContext = useContext(WhizzzaaardPageContext);
  const context = useContext(WhizzzaaardContext);

  useEffect(() => {
    if (context && pageContext) {
      context.registerPrevHandler?.(pageContext, onPrevHandler);
    }
  })
}