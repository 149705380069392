// imports
import * as React                       from 'react';
import {DataHeading, IDataHeadingProps} from "..";
import {RowAndFullCol}                  from "./RowAndFullCol";

// local imports

// constants

// types

// component
export const RowDataHeading = (props: IDataHeadingProps): React.ReactElement => {
  return (
    <RowAndFullCol>
      <DataHeading {...props} />
    </RowAndFullCol>
  );
};
