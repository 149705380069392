import * as React from "react";

interface WhenProps {
  condition: boolean,
  children: React.ReactNode,
}

export class When extends React.Component<WhenProps> {
  public render(){
    return this.props.children;
  }
}