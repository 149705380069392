import React from 'react';
import {Col, Row, Switch} from "antd";

import {Grid} from "../../components/zoo/utilities";
import {useUserSelector} from "../../store";
import {/*generateSecret,*/ generateTotp} from "../../utils/2fa";
import {ITwoFAFormValues, TwoFAForm} from "../../components/TwoFAForm/TwoFAForm";
import {FormikHelpers} from "formik";

export const TwoFATab: React.FC=()=>{
  const users=useUserSelector(state=>state.users);
  const username=users.profile?.username;

  const onTwoFASubmit=(values: ITwoFAFormValues, actions: FormikHelpers<ITwoFAFormValues>)=>{
    actions.setSubmitting(false);
    const totp=generateTotp(secret)
    console.log(totp);
    // dispatch(LoginAction(username, password, next, values.token)).catch(error=>{
    //   console.error("Login Error (2FA):",error.response)
    // });
  }

  const secret='6YUMTHAWP7YOSZCZ';//generateSecret();//
  const issuer="Platinum%20Informatics%20Ltd";
  const algorith="SHA1";
  const digits=6;
  const period=30;
  const uri=`otpauth://totp/${issuer}%3A%20${username}?issuer=${issuer}&secret=${secret}&algorithm=${algorith}&digits=${digits}&period=${period}`;

  return (
      <Col {...Grid.TwentyFourTwentyFourths}>
        <Row gutter={[16,16]}>
          <Col>
            <Switch
                checked={users.profile?.twoFAEnabled}
                checkedChildren={"Enabled"}
                unCheckedChildren={"Disabled"}
            />
          </Col>
        </Row>
        <Row gutter={[16,16]}>
          <Col>
            <TwoFAForm onSubmit={onTwoFASubmit} isLoading={false} qrCodeUri={uri} />
          </Col>
        </Row>
      </Col>
  )
}
