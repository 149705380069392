import React from 'react';
import * as Yup from 'yup';
import {Formik, FormikHelpers} from "formik";
import {Form, Input, SubmitButton} from 'formik-antd';
import {UserOutlined, MailOutlined} from "@ant-design/icons";

export interface ISignUpFormValues {
  username: string
  firstName: string
  lastName: string
  email: string
}

const FormSchema=Yup.object().shape({
  username: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required")
});

interface ISignUpFormProps {
  onSubmit: (values: ISignUpFormValues, actions: FormikHelpers<ISignUpFormValues>)=>void
  isLoading: boolean
}

export const SignUpForm:React.FC<ISignUpFormProps> = (props)=>{
  return (
      <Formik
          onSubmit={props.onSubmit}
          validationSchema={FormSchema}
          initialValues={{username:"", firstName:"", lastName:"", email:""}}
      >
        <Form>
          <Form.Item name={"username"}>
            <Input
                name={"username"}
                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder={"Username"}
            />
          </Form.Item>
          <Form.Item name={"firstName"}>
            <Input
                name={"firstName"}
                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder={"First Name"}
            />
          </Form.Item>
          <Form.Item name={"lastName"}>
            <Input
                name={"lastName"}
                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder={"Last Name"}
            />
          </Form.Item>
          <Form.Item name={"email"}>
            <Input
                name={"email"}
                prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder={"Email"}
            />
          </Form.Item>
          <Form.Item name={"submit"}>
            <SubmitButton
                loading={props.isLoading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{width: "100%"}}>
              Sign Up
            </SubmitButton>
          </Form.Item>
        </Form>
      </Formik>
  );
}
