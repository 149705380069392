import React, {useEffect} from 'react';
import {Card, Col, Layout, Row, Tabs} from "antd";

import {Grid} from '../../components/zoo/utilities';
import {Drawer, DrawerLayout, DrawerMenu, menuItem} from "../../components/zoo";

import "./ProfilePage.less";
import {Breadcrumb} from "../../components/Breadcrumb/Breadcrumb";
import {OverviewTab} from "./OverviewTab";
import {TwoFATab} from "./TwoFATab";
import {useDispatch} from "react-redux";
import {UserGetProfileAction, TTDispatch, useUserSelector} from "../../store";


export const ProfilePage: React.FC = () => {
  const dispatch=useDispatch<TTDispatch>();
  const users=useUserSelector(state=>state.users);
  const {profile}=users;
  const username=profile?.username;

  useEffect(()=>{
    const getUserProfile=()=> {
      dispatch(UserGetProfileAction()).catch(error => {
        console.error("User Profile:", error)
      });
    }
    if(!username) getUserProfile();
  }, [username,dispatch]);

  const routes=[
    {path:"/", breadcrumbName: "DESIMAL"},
    {path: "/profile", breadcrumbName: "Profile"}
  ];

  return (
      <Layout className={"profile-page-layout"}>
        <Drawer logo={require("../../assets/images/desimal3.png")}>
          <DrawerMenu search={false} profile={false}>
            {menuItem('logout', 'Logout', 'sign-out-alt')}
          </DrawerMenu>
        </Drawer>
        <DrawerLayout>
          <Layout.Content className={"drawer-layout-content"}>
            <Col {...Grid.TwentyFourTwentyFourths}>
              <Row>
                <Breadcrumb routes={routes} />
              </Row>
              <Row>
                <Col {...Grid.TwentyFourTwentyFourths}>
                  <Card>
                    <Tabs defaultActiveKey={"overview"}>
                      <Tabs.TabPane tab={"Overview"} key={"overview"}>
                        <OverviewTab />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={"2FA"} key={"2fa"}>
                        <TwoFATab />
                      </Tabs.TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Layout.Content>
        </DrawerLayout>
      </Layout>
  )

}
