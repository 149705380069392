import produce from 'immer';

import {ILoginSessionTokenAction, ISessionTokenReducer, LoginActionType, LoginStateMachineStates} from "./types";

export const LoginReducer=(
    state:ISessionTokenReducer={
      isFetching:false,
      is2FA:false,
      state: LoginStateMachineStates.LOGGED_OUT
    },
    action: ILoginSessionTokenAction): ISessionTokenReducer =>
  produce(state,(draft:ISessionTokenReducer)=>{
    switch(action.type){
      case LoginActionType.LOGIN_SET_SESSION_TOKEN:
        return {...state, sessionToken: action.sessionToken};
      case LoginActionType.LOGIN_SET_FETCHING_SESSION_TOKEN:
        return {...state, isFetching: action.isFetching};
      case LoginActionType.LOGIN_SET_2FA_SESSION_TOKEN:
        return {...state, is2FA: action.is2FA};
      case LoginActionType.LOGIN_SET_STATE_MACHINE_STATE:
        return {...state, state: action.state}
      default: return state;
    }
  }
);


