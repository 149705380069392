import {RootState, TypeSelectorHook} from "..";
import {useSelector as useReduxSelector} from "react-redux";

export type UserProfile={
  username: string
  email: string
  firstName: string
  lastName: string
  twoFAEnabled: boolean
}

// Actions

export enum UserActionType {
  USER_SET_USER_ID="USER_SET_USER_ID",
  USER_SET_FORCE_PASSWORD_CHANGE="USER_SET_FORCE_PASSWORD_CHANGE",
  USER_SET_USER_PROFILE="USER_SET_USER_PROFILE",
}

export interface IUserSetUserIdAction {
  type: UserActionType.USER_SET_USER_ID
  userId: string|undefined
}

export interface IUserSetForcePasswordChangeAction {
  type: UserActionType.USER_SET_FORCE_PASSWORD_CHANGE
  forcePasswordChange: boolean
}

export interface IUserSetUserProfileAction {
  type: UserActionType.USER_SET_USER_PROFILE
  profile: UserProfile|undefined
}

export type IUserAction =
    IUserSetUserIdAction |
    IUserSetForcePasswordChangeAction |
    IUserSetUserProfileAction;

// Reducers

export interface IUserReducer {
  userId?: string
  forcePasswordChange: boolean
  profile?: UserProfile
}

// Selectors

export const useUserSelector: TypeSelectorHook<RootState,IUserReducer> = useReduxSelector;
